import PropTypes from 'prop-types'
import { useCallback, useState } from 'react'
import { AuthContext } from '../../context'
import { redirectToLogin, sessionHasValidToken } from '../../utils/auth'
import { removeFromLocalStorage } from '@derolfgroep/utils/lib/misc/localStorage'
import { HAS_REFRESHED_KEY } from '../../constants'

const AuthProvider = ({ children }) => {
  const hasValidToken = sessionHasValidToken()
  const [isAuthenticated, setIsAuthenticated] = useState(hasValidToken)
  const [isFailedLoginAttempt, setIsFailedLoginAttempt] = useState(false)
  const [isBlockedLoginAttempt, setIsBlockedLoginAttempt] = useState(false)
  const [isSessionExpired, setIsSessionExpired] = useState(false)
  const [isLoadingLogout, setIsLoadingLogout] = useState(false)
  const [isRequestedForgotPassword, setIsRequestedForgotPassword] = useState(false)
  const [isSetPasswordFromLink, setIsSetPasswordFromLink] = useState(false)
  const [isOauthProviderError, setIsOauthProviderError] = useState(false)
  const [isFailedTooManyAttempts, setIsFailedTooManyAttempts] = useState(false)
  const [loginRetryTimeGap, setLoginRetryTimeGap] = useState(null)
  const [forgotPasswordEmail, setForgotPasswordEmail] = useState(null)
  const [isSwitchingSchoolLoading, setIsSwitchingSchoolLoading] = useState(false)

  const resetLoginMessageStates = useCallback(() => {
    setIsFailedLoginAttempt(false)
    setIsBlockedLoginAttempt(false)
    setIsFailedTooManyAttempts(false)
    setIsRequestedForgotPassword(false)
    setIsSetPasswordFromLink(false)
    setLoginRetryTimeGap(null)
    setForgotPasswordEmail(null)
  }, [
    setIsFailedLoginAttempt,
    setIsBlockedLoginAttempt,
    setIsFailedTooManyAttempts,
    setIsRequestedForgotPassword,
    setIsSetPasswordFromLink,
    setLoginRetryTimeGap,
    setForgotPasswordEmail,
  ])

  const handleLogout = useCallback(() => {
    removeFromLocalStorage(HAS_REFRESHED_KEY)
    setIsLoadingLogout(true)
    setIsAuthenticated(false)
    redirectToLogin()
  }, [setIsLoadingLogout])

  const authContextValues = {
    resetLoginMessageStates,
    isAuthenticated,
    setIsAuthenticated,
    isFailedLoginAttempt,
    setIsFailedLoginAttempt,
    isBlockedLoginAttempt,
    setIsBlockedLoginAttempt,
    isSessionExpired,
    setIsSessionExpired,
    handleLogout,
    isLoadingLogout,
    setIsLoadingLogout,
    isRequestedForgotPassword,
    setIsRequestedForgotPassword,
    isSetPasswordFromLink,
    setIsSetPasswordFromLink,
    isOauthProviderError,
    setIsOauthProviderError,
    isFailedTooManyAttempts,
    setIsFailedTooManyAttempts,
    loginRetryTimeGap,
    setLoginRetryTimeGap,
    forgotPasswordEmail,
    setForgotPasswordEmail,
    isSwitchingSchoolLoading,
    setIsSwitchingSchoolLoading,
  }

  return <AuthContext.Provider value={authContextValues}>{children}</AuthContext.Provider>
}

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

AuthContext.Provider.propTypes = {
  value: PropTypes.shape({
    resetLoginMessageStates: PropTypes.func,
    isAuthenticated: PropTypes.bool,
    setIsAuthenticated: PropTypes.func,
    isFailedLoginAttempt: PropTypes.bool,
    setIsFailedLoginAttempt: PropTypes.func,
    isBlockedLoginAttempt: PropTypes.bool,
    setIsBlockedLoginAttempt: PropTypes.func,
    isSessionExpired: PropTypes.bool,
    setIsSessionExpired: PropTypes.func,
    handleLogout: PropTypes.func,
    isLoadingLogout: PropTypes.bool,
    setIsLoadingLogout: PropTypes.func,
    isRequestedForgotPassword: PropTypes.bool,
    setIsRequestedForgotPassword: PropTypes.func,
    isSetPasswordFromLink: PropTypes.bool,
    setIsSetPasswordFromLink: PropTypes.func,
    isOauthProviderError: PropTypes.bool,
    setIsOauthProviderError: PropTypes.func,
    isFailedTooManyAttempts: PropTypes.bool,
    setIsFailedTooManyAttempts: PropTypes.func,
    loginRetryTimeGap: PropTypes.string,
    setLoginRetryTimeGap: PropTypes.func,
    forgotPasswordEmail: PropTypes.string,
    setForgotPasswordEmail: PropTypes.func,
    isSwitchingSchoolLoading: PropTypes.bool,
    setIsSwitchingSchoolLoading: PropTypes.func,
  }),
}

export default AuthProvider

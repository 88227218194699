import { getLocalStorage } from '@derolfgroep/utils/lib/misc/localStorage'
import axios from 'axios'
import has from 'lodash.has'
import { GET, PRIVATE, ROUTE_LOGIN, ROUTE_SESSION_EXPIRED, VALUE_AUTH_TYPE } from '../constants'
import {
  clearSessionToken,
  getPayloadData,
  getSessionAccessToken,
  isTokenExpired,
  sessionHasValidToken,
} from '../utils/auth'

const instance = axios.create({
  baseURL: process.env.API_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json; charset=utf-8',
  },
})

const request = async (options) => {
  const {
    endpoint,
    token = getSessionAccessToken(),
    data,
    authorizationMethod = VALUE_AUTH_TYPE,
    method,
    requestType = PRIVATE, // PRIVATE (default) || PUBLIC
    ...rest
  } = options
  const requestMethod = method ? method.toUpperCase() : GET

  if (token && requestType === PRIVATE) {
    const { sub } = getPayloadData()
    const selectedSchool = getLocalStorage(`zc#.${sub}.currentSchool`)

    instance.defaults.baseURL = `${process.env.API_URL}/school/${selectedSchool.id}`
  }

  instance.interceptors.request.use(
    (config) => {
      const isPrivateReqTokenExpiredOrInvalid =
        requestType === PRIVATE &&
        (isTokenExpired() || !sessionHasValidToken()) &&
        !window.location.href.includes(ROUTE_SESSION_EXPIRED)

      if (isPrivateReqTokenExpiredOrInvalid) {
        window.location.href = isTokenExpired() ? ROUTE_SESSION_EXPIRED : ROUTE_LOGIN
      }

      return config
    },
    (error) => Promise.reject(error)
  )

  instance.defaults.headers.common.Authorization = `${authorizationMethod} ${token}`
  if (requestMethod !== GET) {
    delete instance.defaults.headers.common['Content-Type']
  }

  try {
    const response = await instance.request({
      url: endpoint,
      method: requestMethod,
      data,
      ...rest,
    })

    return response.data
  } catch (error) {
    // Login specific: invalid attempt, too many attempts or blocked by teacher
    const isErrorOnOneOfLoginEndpoints =
      [401, 429].includes(error?.response?.status) &&
      ['/student-login/school/', '/login'].some((endpoint) => error.response.config.url.includes(endpoint))

    if (isErrorOnOneOfLoginEndpoints) {
      clearSessionToken()

      const loginError = new Error(`${error.response.status} (${error.response.statusText})`)
      loginError.status = error.response.status
      loginError.retryAfter = error.response.headers.get('Retry-After') ?? null
      loginError.message = error?.response?.data?.error

      throw loginError
    }

    // network error
    if (!has(error, 'response.status')) {
      throw new Error(`Network error - ${endpoint}: ${error.message}`)
    }

    if (error?.response?.status === 401) {
      // token not valid anymore
      clearSessionToken()
    }

    if (error?.response?.status === 403 && error?.response?.data?.error === 'Blocked') {
      const blockedError = new Error(`${error.response.status} (${error.response.statusText})`)
      blockedError.status = 403
      blockedError.message = 'Blocked'

      throw blockedError
    }

    // api error
    if (error.response) {
      const message = JSON.stringify(error.response)
      throw new Error(`${endpoint}: ${message}`)
    }

    // other
    throw error
  }
}

export default request
